import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { DialogModule } from './dialog/dialog.module';
import { ReactiveFormsModule, FormsModule} from '@angular/forms';
import { NgxUploaderModule } from 'ngx-uploader';
import { SharedPipesModule } from './pipes/shared-pipes.module';

import { LoaderComponent } from 'src/app/shared/components/loader/loader.component';
import { NodataComponent } from 'src/app/shared/components/nodata/nodata.component';

@NgModule({
	declarations: [
		LoaderComponent,
		NodataComponent,
	],
	imports: [
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		NgxUploaderModule,
		DialogModule,
		SharedPipesModule,
	],
	exports: [
		FormsModule,
		ReactiveFormsModule,
		NgxUploaderModule,
		DialogModule,
		SharedPipesModule,
		LoaderComponent,
		NodataComponent,
	],
	providers: [
		
	]
})
export class SharedModule { }
