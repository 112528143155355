import { Component } from '@angular/core';
import { AuthService } from 'src/app/auth/auth.service';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
	  
	title = 'influencer-web';
	authCheckInProgress = true;

	constructor (
		private auth: AuthService,
		private router: Router
	) {
		
	}

	ngOnInit() { 

		var isInIFrame = (window.location != window.parent.location);
		if(isInIFrame==true){
			this.auth.isBrandLogin = true;
		}
		else {
			this.auth.isBrandLogin = false;
		}	

		const localToken = localStorage.getItem(environment.store.token);
		if(localToken) {
			this.auth.authCheck({token : localToken}).subscribe(res => {
				this.authCheckInProgress = false;
				if(res.success) {
					// this.user = res.data;
					localStorage.setItem(environment.store.user, JSON.stringify(res.data));
					this.auth.user = res.data;

					// if(this._brand_id && this._brand_id != 0) {
					// 	this.router.navigate(['/']);
					// } else {
					// 	this.router.navigate(['brand']);	
					// }
					
				} else {
					this.router.navigate(['/login']);
					// this.errors = [res.msg];
					window.parent.postMessage({"message":"authfail", "from":"setUserData"}, '*');
				}
			}, err => {
				console.log(err);
			});
		}
		
	}
	


}
