import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { tap } from 'rxjs/operators';
import { of } from 'rxjs';
import {HttpParams} from '@angular/common/http';
import {HttpHeaders} from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

	accessToken = null;
	user : any;
	active_brand : any;
	active_brand_data : any;
	isBrandLogin : boolean  = false;
	imagesBaseUrl = environment.imagesBaseUrl;

	constructor(private httpClient: HttpClient) { }

	isAuthenticated() {
		if (localStorage.getItem(environment.store.token)) {
            return true;
        } else {
			return false;
		}
	}

	wizardFilled() {
		if (localStorage.getItem(environment.store.user)) {
			const user = JSON.parse(localStorage.getItem(environment.store.user));
			// console.log(typeof user);
			console.log(user);
			console.log('wizardFilled check');
			console.log(typeof user.influencer_onboard != 'undefined' && user.influencer_onboard != null && user.influencer_onboard != '');
			
			if(typeof user.influencer_onboard != 'undefined' && user.influencer_onboard != null && user.influencer_onboard != '') {
				return true;
			} else {
				return false;
			}
		} else {
			return false;
		}
	}

	getUserData() {
		return new Promise((resolve, reject) => {
			const user = JSON.parse(localStorage.getItem(environment.store.user));
			if(user) {
				resolve(user);
			} else {
				reject(null);
			}
		});
	}

	register(fullname:string, username:string, email:string, password:string, brand: any) {
		return this.httpClient.post(`${environment.baseUrl}/influencer/register`, {fullname, username, email, password, brand})
		.pipe(tap((res : any) => {
			console.log(res);
			if(res.success) {
				this.setLocalData(res.data);
				return of(true);
			} else {
				this.user = null;
				this.isAuthenticated();
				return of(false);
			}
		}))
	}

	login(username:string, password:string, brand : any) {
		let obj = {'username' : username, 'password': password, 'brand': brand};
		console.log('====================================');
		console.log(obj);
		console.log('====================================');
		return this.httpClient.post(`${environment.baseUrl}/influencer/login`, obj)
			.pipe(tap((res : any) => {

				if(res.success) {
					localStorage.setItem(environment.store.id, res.data.id);
					localStorage.setItem(environment.store.user, JSON.stringify(res.data));
					localStorage.setItem(environment.store.token, res.data.auth_key);
					this.isBrandLogin = res.data.brand_id != 0 ? true : false;
					// return res;
					return of(true);
				} else {
					this.user = null;
					localStorage.removeItem(environment.store.id);
					localStorage.removeItem(environment.store.user);
					localStorage.removeItem(environment.store.token);
					// return res;
					return of(false);
				}
		}))
	}

	setLocalData(data) {
		localStorage.setItem(environment.store.id, data.id);
		localStorage.setItem(environment.store.user, JSON.stringify(data));
		localStorage.setItem(environment.store.token, data.auth_key);
	}

	removeLocalData() {
		localStorage.removeItem(environment.store.id);
		localStorage.removeItem(environment.store.user);
		localStorage.removeItem(environment.store.token);
	}

	authCheck(object) {

		return this.httpClient.post(`${environment.baseUrl}/influencer/authcheck`, object)
		.pipe(tap((res : any) => {
			console.log(res);
			if(res.success) {
				this.setLocalData(res.data);
				this.isBrandLogin = (typeof res.data.brand_id != 'undefined' && res.data.brand_id) != 0 ? true : false;
				return of(true);
			} else {
				this.user = null;
				this.removeLocalData();
				return of(false);
			}
		}));
	}

	updateProfile(modal) {
		const headers = new HttpHeaders();

		headers.append('Content-Type', 'application/x-www-form-urlencoded');
		headers.append('Accept', 'application/json');

		return this.httpClient.post(`${environment.baseUrl}/influencer/profile/update`, modal, {headers: headers})
		.pipe(tap((res : any) => {
			console.log(res);
			if(res.success) {
				return of(true);
			} else {
				return of(false);
			}
		}));
	}

	updateSocialAccountList(modal) {
		const headers = new HttpHeaders();

		headers.append('Content-Type', 'application/x-www-form-urlencoded');
		headers.append('Accept', 'application/json');

		return this.httpClient.post(`${environment.baseUrl}/influencer/socialaccount/update`, modal, {headers: headers})
		.pipe(tap((res : any) => {
			console.log(res);
			if(res.success) {
				return of(true);
			} else {
				return of(false);
			}
		}));
	}

	updatePassword(modal) {
		const headers = new HttpHeaders();

		headers.append('Content-Type', 'application/x-www-form-urlencoded');
		headers.append('Accept', 'application/json');

		return this.httpClient.post(`${environment.baseUrl}/influencer/password/update`, modal, {headers: headers})
		.pipe(tap((res : any) => {
			console.log(res);
			if(res.success) {
				return of(true);
			} else {
				return of(false);
			}
		}));

	}

	getInfluencerId() {
		return localStorage.getItem(environment.store.id);
	}

	getInfluencer() {
		return localStorage.getItem(environment.store.user);
	}

	login_callback(){
		this.httpClient.get('https://api.instagram.com/v1/users/self/?access_token='+this.accessToken)
		.subscribe((response : any) => {
			localStorage.setItem("userLoggedIn", "1");
			localStorage.setItem("provider", "instagram");
			localStorage.setItem("userData", JSON.stringify(response.data));
		});
	}


	logout() {
		localStorage.removeItem(environment.store.id);
		localStorage.removeItem(environment.store.user);
		localStorage.removeItem(environment.store.token);
		window.parent.postMessage({"message":"logout"}, '*');
	}

}
