import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppComponent } from './app.component';
import { HttpClientModule } from '@angular/common/http';
import { AuthFormComponent } from './auth/auth-form.component';
import { AppRoutingModule } from './app-routing.module';
import { AuthService } from './auth/auth.service';
import { AuthGuard } from './auth/auth-guards';
import { SharedModule } from './shared/shared.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastNoAnimationModule } from 'ngx-toastr';
import { SwiperModule } from 'angular2-useful-swiper';

@NgModule({
	declarations: [
		AppComponent,
		AuthFormComponent,
	],
	imports: [
		SwiperModule,
		BrowserModule,
		HttpClientModule,
		BrowserAnimationsModule,
		AppRoutingModule,
		SharedModule,
		ToastNoAnimationModule.forRoot({
			timeOut: 7000,
			positionClass: 'toast-bottom-right',
			preventDuplicates: false,
		}),
	],
	providers: [
		AuthService,
		AuthGuard
	],
  	bootstrap: [AppComponent]
})
export class AppModule { }
