import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'firstLetter'
})
export class FirstLetterPipe implements PipeTransform {

  transform(value: any, args?: any): string | boolean {
	if (value == null) {
		return value;
	  }
	//   const words = value.split(' ');
	  return value.length > 0 ? value.charAt(0) : value;
  }

}
