import { Component, OnInit, Input} from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';
import { AuthService } from './auth.service';
import { environment } from '../../environments/environment';

@Component({
  selector: 'app-auth-form',
  templateUrl: './auth-form.component.html',
  styleUrls: ['./auth-form.component.scss']
})
export class AuthFormComponent implements OnInit {

	public _brand_id?: number = 0;
	formsState = 'login';
	formTitle = 'FORGOT PASSWORD?';
	formSubTitle = 'Enter your email address and we\'ll get you back on track.';
	registerForm: FormGroup;
	loginForm: FormGroup;
	
    loading = false;
    submitted = false;
    // returnUrl: string;
	errors = [];
	user: any;
	isLoggedIn = false;
	authCheckInProgress = true;
	authSuccess : boolean = false;
	isApproved : boolean = false;
	token : string;
	
	constructor (
		private auth: AuthService,
		private formBuilder: FormBuilder,
        private route: ActivatedRoute,
		private router: Router
	) { 
			
	}
	
	@Input()
	set brand(brand: any) {
		if(brand) this._brand_id = brand;
	}
	
	get brand() { return this._brand_id; }

	changeTab(val) {
		this.formsState = val;
	}

	ngOnInit() {
		
		this.authCheckInProgress = true;
		
        this.registerForm = this.formBuilder.group({
			fullname: ['', Validators.required],
			email: ['', [Validators.required, Validators.email]],
			username: ['', [Validators.required, Validators.pattern(/^[a-zA-Z0-9]+$/)]],
            password: ['', Validators.required]
		});
		
		this.loginForm = this.formBuilder.group({
			email: ['', Validators.required],
            password: ['', Validators.required]
        });

        // get return url from route parameters or default to '/'
		// this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
		
		// setting token in local storage after login redirect
		const url = new URL(window.location.href);
		console.log(url);
		
		if (url.searchParams.get('brand')) {	
			this._brand_id = parseInt(url.searchParams.get('brand'));
			this.auth.active_brand = parseInt(url.searchParams.get('brand'));
			this.auth.isBrandLogin = true;
		} 
 
		if (url.searchParams.get('token')) {
			this.authCheckInProgress = true;
			const token = url.searchParams.get('token');
			localStorage.setItem(environment.store.token, token);
			this.setUserData(token);
			window.parent.postMessage({"message":"authsuccess", "from":"ngOnInit"}, '*');
			//removing query string after login/register redirect with token
			// var yourCurrentUrl = window.location.href.split('?')[0]; 
			// window.history.replaceState({}, '', yourCurrentUrl ); 
		} else {

			// check locally
			this.auth.getUserData().then((data : any) => {
				if(data) {
					window.parent.postMessage({"message":"authsuccess", "from":"ngOnInit"}, '*');
					// if(this._brand_id && this._brand_id != 0) {
						this.router.navigate(['/']);
					// } else {
					// 	this.router.navigate(['brand']);	
					// }	
				} else {
					this.alreadyLoggedIn();
				}
			}).catch(err => {
				this.alreadyLoggedIn();
			});
		}

    }

	alreadyLoggedIn() {
		// move to dashboard if token exist
		const localToken = localStorage.getItem(environment.store.token);
		if(localToken) {	
			this.setUserData(localToken);
		} else {
			this.authCheckInProgress = false;
			window.parent.postMessage({"message":"authfail", "from":"ngOnInit else"}, '*');
		}
	}

	appendRemoveTokenFromUrl(token, data) {
		var yourCurrentUrl = window.location.href.split('?')[0]; 
		window.history.replaceState({}, '', yourCurrentUrl ); 
		if(data.is_approved == 1) {
			this.isApproved = true;
		} else {
			this.isApproved = false;
		}
		this.authSuccess =  true;
		// this.trustedUrl = this.sanitizer.bypassSecurityTrustResourceUrl(`${this.inflencerPath}/login?token=${token}&success=true&id=${data.id}&iframe=1`);
		// this.renderer.setAttribute(this.myDiv.nativeElement, 'innerHTML', this.content);
	}

	setUserData(token) {
		this.token = token;
		this.auth.authCheck({
			token: token, 
			brand: this._brand_id
		}).subscribe(res => {
			this.authCheckInProgress = false;
			if(res.success) {
				this.user = res.data;
				localStorage.setItem(environment.store.user, JSON.stringify(res.data));
				this.appendRemoveTokenFromUrl(token, res.data);

				if(this._brand_id && this._brand_id != 0) {
					this.router.navigate(['/']);
				} else {
					this.router.navigate(['brand']);	
				}
			} else {
				this.errors = [res.msg];
				window.parent.postMessage({"message":"authfail", "from":"setUserData"}, '*');
			}
			
		}, err => {
			console.log(err);
		});
	}

	fbLogin() {
		this.submitted = false;
		history.pushState("", document.title, window.location.pathname); // r emove hash value on click // redirect url not work if 
		var popupWidth = 700,
        popupHeight = 500,
        popupLeft = (window.screen.width - popupWidth) / 2,
		popupTop = (window.screen.height - popupHeight) / 2;
		// var currentLocation = window.location;

		var redirect_uri = window.location.href+'/';
		var currentLocation = redirect_uri.replace(/^\/|\/$/g, ''); // remove slasg at the end
		
		var url = `${environment.baseUrl}/influencer/facebook/login?brand=${this._brand_id}&redirect=${currentLocation}`;
		// Url needs to point to instagram_auth.php
		var popup = window.open(url, '', 'width='+popupWidth+',height='+popupHeight+',left='+popupLeft+',top='+popupTop+'');

	}

	instaLogin() {
		this.submitted = false;
		history.pushState("", document.title, window.location.pathname); // r emove hash value on click // redirect url not work if 
		var popupWidth = 700,
        popupHeight = 500,
        popupLeft = (window.screen.width - popupWidth) / 2,
		popupTop = (window.screen.height - popupHeight) / 2;
		// var currentLocation = window.location;

		var redirect_uri = window.location.href+'/';
		var currentLocation = redirect_uri.replace(/^\/|\/$/g, ''); // remove slasg at the end
		
		var url = `${environment.baseUrl}/influencer/instagram/login?brand=${this._brand_id}&redirect=${currentLocation}`;

		// Url needs to point to instagram_auth.php
		var popup = window.open(url, '', 'width='+popupWidth+',height='+popupHeight+',left='+popupLeft+',top='+popupTop+'');
		
	}

    // convenience getter for easy access to form fields
    get f() { return this.loginForm.controls; }

	onLoginSubmit() {
		this.submitted = true;
		const data = this.loginForm.value;
		this.loading = true;
		this.errors = [];
		console.log(data);
		if (this.loginForm.invalid) {
            return;
		}
		
        // stop here if form is invalid
        if (!this.loginForm.invalid) {
			this.auth.login(data.email, data.password, this._brand_id)
			.subscribe((res : any) => {
				if(res.success) {
					this.user = res.data;
					localStorage.setItem(environment.store.user, JSON.stringify(res.data));
					localStorage.setItem(environment.store.token, res.data.auth_key);
					this.appendRemoveTokenFromUrl(res.data.auth_key, res.data);
					if(this._brand_id && this._brand_id != 0) {
						this.router.navigate(['/']);
					} else {
						this.router.navigate(['brand']);
					}
				} else {
					this.errors = res.msg;
				}
				this.loading = false;
			}, error => {
				this.loading = false;
			});
        }
	}

    onRegSubmit() {
        this.submitted = true;
		this.errors = [];
        // stop here if form is invalid
        if (this.registerForm.invalid) {
            return;
        }
		
		const data = this.registerForm.value;
		this.loading = true;
		
        this.auth.register(data.fullname, data.username, data.email, data.password, this._brand_id)
            .pipe(first())
            .subscribe(
                (res : any) => {
					if(res.success) {
						// this.router.navigate(['/']);
						localStorage.setItem(environment.store.user, JSON.stringify(res.data));
						localStorage.setItem(environment.store.token, res.data.auth_key);
						this.appendRemoveTokenFromUrl(res.data.auth_key, res.data);
						if(this._brand_id && this._brand_id != 0) {
							this.router.navigate(['/']);
						} else {
							this.router.navigate(['brand']);
						}
						
					} else {
						this.errors = res.msg;
					}
					this.loading = false;
                },
                error => {
					this.loading = false;
                    this.errors = error;
                });
    }

}
