import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthFormComponent } from './auth/auth-form.component';
import { AuthGuard } from './auth/auth-guards';
import { WizardGuard } from './auth/wizard-guards';
import { PathLocationStrategy, LocationStrategy } from '@angular/common';
import { TourComponent } from './tour/tour.component';

const appRoutes: Routes = [
	{ path: 'login', component: AuthFormComponent },
	{ path: 'onboard', loadChildren: () => import('./tour/tour.module').then(mod => mod.TourModule), canActivate: [WizardGuard]},
	{ path: '', loadChildren: () => import('./admin/admin.module').then(mod => mod.AdminModule), canActivate: [AuthGuard] },
	// { path: '', redirectTo: '', pathMatch: 'prefix' },
    // otherwise redirect to home
    { path: '**', redirectTo: '' }
];

@NgModule({
    imports: [RouterModule.forRoot(appRoutes)],
	exports: [RouterModule],
	providers: [{provide: LocationStrategy, useClass: PathLocationStrategy}]
})
export class AppRoutingModule {}
