import { NgModule } from '@angular/core';
import { FirstLetterPipe } from './first-letter/first-letter.pipe';
import { SanitizeHtmlPipe } from './sanitize-html/sanitize-html.pipe';

@NgModule({
    imports: [],
	exports: [	
		FirstLetterPipe,
		SanitizeHtmlPipe
    ],
	declarations: [FirstLetterPipe, SanitizeHtmlPipe],
})
export class SharedPipesModule { }
