// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

// export const environment = {
//   production: false,
//   baseWebUrl: 'https://iblossom.local',
//   baseUrl: 'https://iblossom.local/shopify',
//   imagesBaseUrl: 'https://iblossom.local/images/influencer_client'
// };

// export const environment = {
// 	production: false,
// 	baseWebUrl: 'https://stori.test',
// 	baseUrl: 'https://stori.cloud/shopify',
// 	imagesBaseUrl: 'https://stori.cloud/images/influencer_client',
// 	store: {
// 		token: 'local_influencer_token',
// 		user: 'local_influencer_info',
// 	}
// };

export const environment = {
	production: true,
	baseWebUrl: 'https://stori.cloud',
	baseUrl: 'https://stori.cloud/shopify',
	imagesBaseUrl: 'https://stori.cloud/images/influencer_client',
	store: {
		token: 'live_influencer_token',
		user: 'live_influencer_info',
		id: 'live_influencer_id'
	}
};

  
/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
