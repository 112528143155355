import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AuthService } from './auth.service';

@Injectable({ providedIn: 'root' })
export class WizardGuard implements CanActivate {

    constructor(private router: Router, private auth : AuthService) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
     
		if (!this.auth.isAuthenticated()) {
			// logged in so return true
			this.router.navigate(['/login'], { queryParams: { returnUrl: state.url }});
            return false;
		}
		
		if (this.auth.wizardFilled()) {
			this.router.navigate(['/']);
            return true;
        }

        // not logged in so redirect to login page with the return url
        return true;
    }
}