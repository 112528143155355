import { Component, Type, ComponentFactoryResolver, ViewChild, OnDestroy, ComponentRef, AfterViewInit, ChangeDetectorRef, Inject, Renderer2} from '@angular/core';
import { InsertionDirective } from './insertion.directive';
import { Subject } from 'rxjs';
import { DialogRef } from './dialog-ref';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'app-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.css']
})
export class DialogComponent implements AfterViewInit, OnDestroy {
	
	componentRef: ComponentRef<any>;

	@ViewChild(InsertionDirective, {static: false}) insertionPoint: InsertionDirective;

	private readonly _onClose = new Subject<any>();
	public onClose = this._onClose.asObservable();

	childComponentType: Type<any>;

	constructor(
		private componentFactoryResolver: ComponentFactoryResolver, 
		private cd: ChangeDetectorRef, 
		private dialogRef: DialogRef,
		@Inject(DOCUMENT) private document: Document,
		private renderer: Renderer2,
	) 
	{

	}

	ngAfterViewInit() {
		this.loadChildComponent(this.childComponentType);
		this.cd.detectChanges();
	}

	onOverlayClicked(evt: MouseEvent) {
		this.dialogRef.close();
	}

	onDialogClicked(evt: MouseEvent) {
		evt.stopPropagation();
	}

	loadChildComponent(componentType: Type<any>) {
		let componentFactory = this.componentFactoryResolver.resolveComponentFactory(componentType);

		let viewContainerRef = this.insertionPoint.viewContainerRef;
		viewContainerRef.clear();

		this.componentRef = viewContainerRef.createComponent(componentFactory);
		this.renderer.addClass(this.document.body, 'modal-open');

	}

	ngOnDestroy() {
		if (this.componentRef) {
			this.componentRef.destroy();
			this.renderer.removeClass(this.document.body, 'modal-open');
		}
	}

	close() {
		this._onClose.next();
	}
}
